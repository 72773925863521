import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useEffect, useState, useCallback, useMemo } from "react"
import styled, { css, ThemeProvider } from "styled-components"
import Grid from "./lib/grid"
import { useMatch } from "@reach/router"
import locations from "./locations"
import headerLogo from "../../images/logo_header.svg"
import headerLogoLight from "../../images/logo_header-light.svg"
import headerLogoTixsee from "../../images/Tixsee-dark.svg"
import headerLogoTixseeLight from "../../images/Tixsee-light.svg"
import Jumbotron from "./jumbotron"

const HeaderContainer = styled.header`
  padding: ${props => props.theme.verticalSpacing(1)} 0;
  background-color: ${props =>
    props.theme.light ? props.theme.oilslick5 : props.theme.oilslick90};
  top: 0;
  z-index: 100;
  transition: background-color 300ms;
  @media only screen and (min-width: ${props => props.theme.tablet}px) {
    position: sticky;
    padding: ${props => props.theme.verticalSpacing(1.5)} 0;
  }
`

const HeaderContents = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const NavLinks = styled.ul`
  display: flex;
  margin: 0;
  flex-direction: column;
  align-items: flex-end;
  list-style: none;
  padding-inline-start: 0;

  @media only screen and (min-width: ${props => props.theme.tablet}px) {
    flex-direction: row;
    align-items: center;
  }
`

const ImageLink = styled(Link)`
  font-size: 0;
`

const NavLinkWrapper = ({ isCurrent, ...props }) => <Link {...props} />

const StyledNavLink = styled(NavLinkWrapper)`
  color: ${props => (props.theme.light ? props.theme.oilslick : "white")};
  text-transform: uppercase;
  letter-spacing: 2px;
  line-height: 3.2rem;
  font-size: 1.6rem;
  font-weight: 500;
  text-decoration: none;
  margin-left: 50px;
  :hover {
    color: ${props => props.theme.highlightBlue};
  }

  ${props =>
    props.isCurrent
      ? css`
          color: ${props.theme.highlightBlue};
        `
      : ""}
`

const NavLink = props => {
  const match = !!useMatch(props.to)
  return (
    <li>
      <StyledNavLink isCurrent={match} {...props} />
    </li>
  )
}

const useLightHeader = function(initial) {
  const [light, setLight] = useState(initial)

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([{ isIntersecting }]) => setLight(!isIntersecting),
      {
        root: null,
        rootMargin: "0px 0px -90% 0px",
        threshold: 0.01,
      }
    )
    for (const item of document.querySelectorAll(".section-dark")) {
      observer.observe(item)
    }
    return () => {
      observer.disconnect()
    }
  }, [setLight])
  return light
}

const Header = ({ siteTitle, initialHeaderLight, tixsee }) => {
  const light = useLightHeader(initialHeaderLight)
  const theme = useMemo(() => ({ light }), [light])
  return (
    <>
      <ThemeProvider theme={theme}>
        <HeaderContainer>
          <Grid>
            <Grid.Row>
              <Grid.Col>
                <HeaderContents>
                  <ImageLink to="/">
                    <img
                      css={css`
                        height: ${tixsee ? 3 : 3.2}rem;
                        max-height: 48px;
                        @media only screen and (min-width: ${props =>
                            props.theme.tablet}px) {
                          height: 48px;
                        }
                      `}
                      src={
                        tixsee
                          ? light
                            ? headerLogoTixsee
                            : headerLogoTixseeLight
                          : light
                          ? headerLogoLight
                          : headerLogo
                      }
                      alt={siteTitle}
                      title={siteTitle}
                    />
                  </ImageLink>
                  {!!locations.length && (
                    <nav>
                      <NavLinks>
                        {locations.map(({ location, name }) => (
                          <NavLink key={name} to={location}>
                            {name}
                          </NavLink>
                        ))}
                      </NavLinks>
                    </nav>
                  )}
                </HeaderContents>
              </Grid.Col>
            </Grid.Row>
          </Grid>
        </HeaderContainer>
      </ThemeProvider>
    </>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
