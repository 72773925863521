/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import styled, { createGlobalStyle, ThemeProvider } from "styled-components"
import Helmet from "react-helmet"

import Header from "./header"
import Footer from "./footer"
import theme from "./theme"
import favicon from "../../images/favicon.ico"

const GlobalStyle = createGlobalStyle`
  * {
    font-family: "IBM Plex Sans", "Helvetica Neue", "Arial", "sans-serif";
    box-sizing: border-box;
  }

  h1 {
    margin: 0;
  }

  body {
    margin: 0;
    min-height: 100vh;
    font-size: 1.2rem;
    position: relative;
    color: ${props => props.theme.oilslick};
    overflow-x: hidden;
  }

  html {
    font-size: 62.5%; /* 10 px but allows browser settings to override */
  }
`

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`

const StyledMainContent = styled.main`
  background-color: ${props => props.theme.oilslick5};
  flex-grow: 1;
  padding-bottom: 32px;
`

const Layout = ({
  children,
  tixsee = false,
  initialHeaderLight = false,
  headerOverride = null,
}) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Helmet>
        <link rel="icon" href={favicon} type="image/x-icon" />
        <link
          href="https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <AppContainer>
          {headerOverride ?? (
            <Header
              siteTitle={data.site.siteMetadata.title}
              initialHeaderLight={initialHeaderLight}
              tixsee={tixsee}
            />
          )}
          <StyledMainContent>{children}</StyledMainContent>
          <Footer />
        </AppContainer>
      </ThemeProvider>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  tixsee: PropTypes.bool,
}

export default Layout
