export default [
  {
    name: "About",
    location: "/about",
  },
  {
    name: "Press",
    location: "/news",
  },
  {
    name: "Careers",
    location: "/careers",
  },
]
