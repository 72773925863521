import React from "react"
import styled from "styled-components"
import Grid from "./lib/grid"

const JumbotronContainer = styled.div`
  padding: 0 0 ${props => props.theme.verticalSpacing(2)};
  color: ${props => props.theme.oilslick90};
  &.section-dark {
    color: white;
    background-color: ${props => props.theme.oilslick90};
  }
  @media only screen and (min-width: ${props => props.theme.tablet}px) {
    padding: ${props => props.theme.verticalSpacing(2)} 0
      ${props => props.theme.verticalSpacing(5)};
  }
`

const Jumbotron = ({ children, dark = true, className }) => (
  <JumbotronContainer
    className={dark ? `${className} section-dark` : className}
  >
    <Grid>
      <Grid.Row>
        <Grid.Col>{children}</Grid.Col>
      </Grid.Row>
    </Grid>
  </JumbotronContainer>
)

Jumbotron.Title = styled.h1`
  font-size: 3.2rem;
  font-weight: 300;
  line-height: 4rem;
  margin-bottom: ${props => props.theme.verticalSpacing(1)};
  margin-top: 0;
  @media only screen and (min-width: ${props => props.theme.tablet}px) {
    font-size: 6.4rem;
    line-height: 8rem;
  }
`

Jumbotron.Description = styled.p`
  font-size: 1.8rem;
  line-height: 2.4rem;
  margin-bottom: 0;
  font-weight: 300;
  @media only screen and (min-width: ${props => props.theme.tablet}px) {
    font-size: 3rem;
    line-height: 4rem;
  }
`

const SubDescriptionContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${props => props.theme.verticalSpacing(4)};
`

const SubDescriptionWrapper = styled.p`
  font-size: 1.8rem;
  line-height: 3.2rem;
  padding-right: ${props => props.theme.horizontalSpacing(1)};
  z-index: 1;
  margin: 0;
`

const SubDescriptionLine = styled.div`
  height: 1px;
  background-color: ${props => props.theme.oilslick};
  flex-grow: 1;
`

Jumbotron.SubDescription = props => (
  <SubDescriptionContainer>
    <SubDescriptionWrapper {...props} />
    <SubDescriptionLine />
  </SubDescriptionContainer>
)

Jumbotron.ImageList = styled.div`
  line-height: 0;

  img {
    margin-right: 50px;
  }
`

export default Jumbotron
