import React from "react"
import styled, { css } from "styled-components"
import PropTypes from "prop-types"

const Grid = styled.div`
  margin: 0 auto;
  max-width: 90vw;

  @media only screen and (min-width: ${props => props.theme.tablet}px) {
    max-width: ${props => props.theme.tablet}px;
  }

  @media only screen and (min-width: ${props => props.theme.desktop}px) {
    max-width: ${props => props.theme.desktop}px;
  }
`

Grid.Row = styled.div`
  display: flex;
  flex-directon: row;
  flex-wrap: wrap;
`

// Swallow the fill prop
const ColWrapper = ({
  fill,
  sm,
  md,
  lg,
  smBottom,
  mdBottom,
  lgBottom,
  ...props
}) => <div {...props} />

Grid.Col = styled(ColWrapper)`
  padding: 0 ${props => props.theme.horizontalSpacing(1)};
  width: calc(${props => props.sm || 12} * (90vw / 12));
  ${props =>
    props.fill
      ? css`
          flex-grow: 1;
        `
      : ""}
  ${props =>
    props.lgBottom
      ? css`
          margin-bottom: ${props.theme.verticalSpacing(props.lgBottom)};
        `
      : ""}

  @media only screen and (max-width: ${props => props.theme.tablet - 1}px) {
    ${props =>
      props.smBottom
        ? css`
            margin-bottom: ${props.theme.verticalSpacing(props.smBottom)};
          `
        : ""}
  }

  @media only screen and (min-width: ${props => props.theme.tablet}px) {
    width: ${props =>
      (props.md || props.sm || 12) * (props.theme.tablet / 12)}px;
  }

  @media only screen and (max-width: ${props => props.theme.desktop - 1}px) {
    ${props =>
      props.mdBottom
        ? css`
            margin-bottom: ${props.theme.verticalSpacing(props.mdBottom)};
          `
        : ""}
  }

  @media only screen and (min-width: ${props => props.theme.desktop}px) {
    width: ${props =>
      (props.lg || props.md || props.sm || 12) * (props.theme.desktop / 12)}px;
  }
`

const pt1to12 = PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12])

Grid.Col.propTypes = {
  sm: pt1to12,
  md: pt1to12,
  lg: pt1to12,
  smBottom: PropTypes.number,
  mdBottom: PropTypes.number,
  lgBottom: PropTypes.number,
  fill: PropTypes.bool,
}

export default Grid
