import React from "react"
import styled, { css } from "styled-components"
import Grid from "./lib/grid"
import { Link, useStaticQuery } from "gatsby"
import locations from "./locations"
import { useMatch } from "@reach/router"
import logo from "../../images/logo_footer.svg"
import { graphql } from "gatsby"
import Image from "gatsby-image"

const StyledFooter = styled.footer`
  background-color: ${props => props.theme.oilslick10};
  padding: ${props => props.theme.verticalSpacing(2)} 0;
  @media only screen and (min-width: ${props => props.theme.desktop}px) {
    padding: ${props => props.theme.verticalSpacing(3)} 0;
  }
`

const RightCol = styled(Grid.Col)`
  position: relative;
  padding-top: ${props => props.theme.verticalSpacing(1.5)};

  @media only screen and (min-width: ${props => props.theme.desktop}px) {
    display: flex;
    flex-direction: row;
    align-items: center;
    ::before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      border-top: 1px solid #d8dbe1;
    }
  }
`

const PumpjackLogoImg = styled.img`
  display: block;
  width: 25px;
`

const CopyrightText = styled.p`
  margin: ${props => props.theme.verticalSpacing(1)} 0 0;
  color: ${props => props.theme.oilslick};
  font-size: 1.2rem;
  line-height: 1.6rem;
  @media only screen and (min-width: ${props => props.theme.desktop}px) {
    margin: 0 10px;
  }
`

const WithPrideText = styled.p`
  margin: ${props => props.theme.verticalSpacing(1)} 0 0;
  color: ${props => props.theme.oilslick};
  font-size: 1.2rem;
  font-weight: bold;
  line-height: 2.4rem;
  @media only screen and (min-width: ${props => props.theme.desktop}px) {
    margin: 0 ${props => props.theme.horizontalSpacing(1)};
  }
`

const PoliciesHeader = styled.dt`
  color: ${props => props.theme.oilslick};
  text-transform: uppercase;
  font-size: 1.2rem;
  font-weight: bold;
  letter-spacing: 2px;
  line-height: 2.4rem;
`

const PoliciesList = styled.dl`
  list-style: none;
  margin: 0;
  padding: 0;
`

const PolicyWrapper = styled.dd`
  font-size: 1.2rem;
  line-height: 2.4rem;
  margin: 0;
`

const PolicyLink = styled(Link)`
  color: ${props => props.theme.oilslick};
  text-decoration: none;
  :hover {
    color: ${props => props.theme.highlightBlue};
  }
`

const Policy = ({ children, to }) => (
  <PolicyWrapper>
    <PolicyLink to={to}>{children}</PolicyLink>
  </PolicyWrapper>
)

const LocationList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  @media only screen and (min-width: ${props => props.theme.desktop}px) {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`

const Location = styled.li`
  color: ${props => props.theme.oilslick};
  text-transform: uppercase;
  font-size: 1.2rem;
  font-weight: 500;
  letter-spacing: 2px;
  line-height: 2.4rem;
  :not(:last-child) {
    margin-right: ${props => props.theme.horizontalSpacing(1)};
  }
`

const NavList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`

const LinkWrapper = ({ isCurrent, ...props }) => <Link {...props} />

const StyledLink = styled(LinkWrapper)`
  color: ${props => props.theme.oilslick};
  text-transform: uppercase;
  letter-spacing: 2px;
  line-height: 2.4rem;
  font-size: 1.2rem;
  text-decoration: none;
  display: inline-block;
`

const AdditionalLink = props => (
  <div>
    <StyledLink
      css={css`
        font-weight: bold;
        color: ${props => props.theme.highlightBlue};
      `}
      {...props}
    />
  </div>
)

const StyledNavLink = styled(StyledLink)`
  font-weight: bold;
  :hover {
    color: ${props => props.theme.highlightBlue};
  }
  ${props =>
    props.isCurrent
      ? css`
          color: ${props.theme.highlightBlue};
        `
      : ""}
`

const NavLink = props => {
  const match = !!useMatch(props.to)
  return (
    <li>
      <StyledNavLink isCurrent={match} {...props} />
    </li>
  )
}

export default function Footer() {
  const data = useStaticQuery(graphql`
    query {
      privacyShield: file(relativePath: { eq: "privacy_shield_logo.png" }) {
        childImageSharp {
          fixed(height: 48) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
    }
  `)
  return (
    <StyledFooter>
      <Grid>
        <Grid.Row>
          {!!locations.length && (
            <Grid.Col sm={12} smBottom={1} md={3}>
              <NavList>
                {locations.map(({ location, name }) => (
                  <NavLink key={name} to={location}>
                    {name}
                  </NavLink>
                ))}
              </NavList>
            </Grid.Col>
          )}
          <Grid.Col sm={12} md={6} fill lgBottom={1.5}>
            <PoliciesList>
              <PoliciesHeader>Policies</PoliciesHeader>
              <Policy to="/privacy">Pumpjack and Privacy</Policy>
              {/* <Policy to="/terms">Terms of Use</Policy> */}
              <Policy to="/anti-slavery">Anti-Slavery</Policy>
            </PoliciesList>
          </Grid.Col>
          <Grid.Col
            md={3}
            lgBottom={1.5}
            css={css`
              align-self: flex-end;
              text-align: right;
              margin-left: auto;
              align-self: flex-end;
              @media only screen and (max-width: ${props =>
                  props.theme.desktop - 1}px) {
                display: none;
              }
            `}
          >
            <div>
              <Image
                fixed={data.privacyShield.childImageSharp.fixed}
                alt="Privacy Shield Framework logo"
              />
            </div>
          </Grid.Col>
        </Grid.Row>
      </Grid>
      <div
        css={css`
          width: 100%;
          height: 1px;
          background-color: #d8dbe1;
          @media (min-width: ${props => props.theme.desktop}px) {
            display: none;
          }
        `}
      />
      <Grid>
        <Grid.Row>
          <RightCol sm={12}>
            <PumpjackLogoImg src={logo} alt="Pumpjack Dataworks" />
            <CopyrightText>© Pumpjack Dataworks, Inc.</CopyrightText>
            <div
              css={css`
                flex-grow: 1;
              `}
            />
            <WithPrideText>With pride from</WithPrideText>
            <LocationList>
              <Location>Fort Worth</Location>
              <Location>Dallas</Location>
              <Location>London</Location>
              <Location>Karachi</Location>
            </LocationList>
            <div
              css={css`
                margin-top: ${props => props.theme.verticalSpacing(1)};
                @media only screen and (min-width: ${props =>
                    props.theme.desktop}px) {
                  display: none;
                }
              `}
            >
              <Image
                fixed={data.privacyShield.childImageSharp.fixed}
                alt="Privacy Shield Framework logo"
              />
            </div>
          </RightCol>
        </Grid.Row>
      </Grid>
    </StyledFooter>
  )
}
