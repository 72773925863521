const theme = {
  oilslick: '#15181e',
  oilslick90: '#222730',
  oilslick5: '#fafbfc',
  oilslick10: '#f3f4f6',
  oilslick20: '#e9ebf0',
  oilslick30: '#d8dbe1',
  highlightBlue: '#00aaff',
  verticalSpacing: (times = 1) => `${times * 1.6}rem`,
  horizontalSpacing: (times = 1) => `${times * 1.5}rem`,
  desktop: 960,
  tablet: 600
}

export default theme;